import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import Router from './routes'
import {
    store,
    ThriveApplication,
    isRunningInWebview,
    postMessageToWebview,
    HybridEventType
} from '@thriveglobal/thrive-web-core'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { getOrGenerateUserId } from './utils/getTempUserId'
import { useRedirectToAppIfLoggedIn } from './hooks/useRedirectToAppIfLoggedIn'
import { useRedirectToWebexLaunchIfRunningInWebex } from './hooks/useRedirectToWebexLaunchIfRunningInWebex'

const loadi18n = (lang: string) =>
    fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)
export default function Root(props) {
    const client = new ApolloClient({
        uri: process.env.APOLLO_GATEWAY_PUBLIC,
        cache: new InMemoryCache(),
        // name: 'thrive-web-sign-in', // Commented out for now as triggers CORS error
        connectToDevTools: true
    })
    const userId = getOrGenerateUserId()
    useRedirectToAppIfLoggedIn()
    useRedirectToWebexLaunchIfRunningInWebex()

    useEffect(() => {
        if (isRunningInWebview()) {
            postMessageToWebview({ type: HybridEventType.REQUEST_DEVICE_ID })
        }
    }, [])

    return (
        <ThriveApplication
            messagesLoaders={[loadi18n]}
            apolloClientOptions={{
                cacheOptions: {},
                name: 'thrive-web-sign-in'
            }}
            sentryDsn={process.env.SENTRY_DSN}
            commitHash={process.env.GIT_REF}
            sentryUnauthenticatedUserId={userId}
        >
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <section>
                        <Router />
                    </section>
                </Provider>
            </ApolloProvider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
