import { ROUTES } from '../routes'
import {
    AuthState,
    captureException,
    captureMessage,
    store,
    isWebexTest
} from '@thriveglobal/thrive-web-core'
import { selectIsTokenExpired } from '@thriveglobal/thrive-web-auth-core'
import { useEffect } from 'react'
import { getWebexAppRedirectPath } from '../utils/redirectUtils'

export function redirectToWebexLaunchIfRunningInWebex() {
    try {
        const isWebexSubdomain = isWebexTest()
        if (!isWebexSubdomain) {
            return
        }
        const authStore = store.getState().auth
        const isTokenExpired = selectIsTokenExpired(authStore as AuthState)
        const userNotSignedIn = !authStore.isLoggedIn || isTokenExpired

        const isSessionEndedRoute = window.location.href.includes(
            ROUTES.LOGOUT_CONFIRMATION
        )

        const isLoginCallbackRoute = window.location.href.includes(
            ROUTES.LOGIN_CALLBACK
        )

        if (userNotSignedIn && !isSessionEndedRoute && !isLoginCallbackRoute) {
            const redirectUrl = getWebexAppRedirectPath()
            captureMessage(
                `Webex user not logged in, redirecting to: ${redirectUrl}`
            )
            window.location.assign(redirectUrl)
        }
    } catch (error) {
        captureException(error, {
            message: 'Unable to redirect into the webex app'
        })
    }
}

export function useRedirectToWebexLaunchIfRunningInWebex() {
    useEffect(() => {
        redirectToWebexLaunchIfRunningInWebex()
    }, [])
}
