import React, { PropsWithChildren } from 'react'
import { Grid, Container, styled, useMediaQuery, Box } from '@mui/material'
import {
    useAppSelector,
    isRunningInWebview
} from '@thriveglobal/thrive-web-core'
import { useTheme, MarkLogoMulti } from '@thriveglobal/thrive-web-leafkit'
import NavBar from '../../elements/NavBar'
import { DefaultLayoutProps } from '../layoutTypes'
import DeviceIdDialog from '../../../components/elements/DeviceIdDialog'

const DefaultLayoutContainer = styled(
    Container,
    {}
)<DefaultLayoutProps>(({ theme, variant = 'dark' }) => ({
    maxWidth: 'unset!important',
    padding: '0!important',
    height: '100vh',
    width: '100vw',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor:
        variant === 'dark'
            ? theme.palette.primary.main
            : theme.palette.background.paper,
    color:
        variant === 'dark'
            ? theme.palette.primary.contrastText
            : theme.palette.primary.dark,
    overflow: 'auto'
}))

const DefaultLayout: React.FC<PropsWithChildren<DefaultLayoutProps>> = ({
    children,
    variant,
    redirect
}) => {
    const theme = useTheme()
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'))
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const { deviceId } = useAppSelector((state) => state.hybrid)

    return (
        <DefaultLayoutContainer variant={variant}>
            <NavBar variant={variant} redirect={redirect} />
            <Grid
                container
                wrap="nowrap"
                flexDirection={isMobileView ? 'column' : 'row'}
                sx={{
                    height: 'calc(100% - var(--navbar-height))'
                }}
            >
                <Grid sm={12} md={8} item display={'flex'}>
                    <Box
                        sx={{
                            my: 'auto',
                            padding: '6rem 7.9rem',
                            [theme.breakpoints.down('md')]: {
                                padding: '2.4rem',
                                marginBottom: 'auto',
                                display: !isSmall ? 'flex' : 'block'
                            }
                        }}
                    >
                        {children}
                    </Box>
                </Grid>
                {!isMobileView && (
                    <Grid md={4} item>
                        <Box
                            sx={{
                                overflow: 'hidden',
                                color: theme.palette.accent.main,
                                height: '100%',
                                padding: '0'
                            }}
                        >
                            <MarkLogoMulti
                                sx={{
                                    height: '100%',
                                    width: 'auto',
                                    img: {
                                        height: '100%',
                                        objectFit: 'cover'
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
            {isRunningInWebview() && deviceId && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'end',
                        position: 'absolute',
                        bottom: 0,
                        padding: 1
                    }}
                >
                    <DeviceIdDialog variant={variant} deviceId={deviceId} />
                </Box>
            )}
        </DefaultLayoutContainer>
    )
}

export default DefaultLayout
