import React from 'react'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import {
    useTheme,
    IconButton,
    CoreTypography,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import { Dialog, DialogTitle, DialogContent, Stack } from '@mui/material'
import { getCookie } from '@thriveglobal/thrive-web-core'

const messages = defineMessages({
    deviceIdLabel: {
        defaultMessage: 'Open device id modal',
        description: 'Label for button to open device id modal'
    }
})

export interface DeviceIdDialogProps {
    variant: string
    deviceId: string
}
const DeviceIdDialog: React.FC<DeviceIdDialogProps> = ({
    variant,
    deviceId
}) => {
    const intl = useIntl()
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)
    const appVersion = getCookie('THRIVE_APP_VERSION')

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const componentVariant = variant || 'dark'
    const color = componentVariant === 'light' ? 'primary' : 'info'

    return (
        <>
            {deviceId && (
                <>
                    <IconButton
                        aria-label={intl.formatMessage(messages.deviceIdLabel)}
                        onClick={handleClickOpen}
                        color={color}
                        data-testid="deviceIdButton"
                    >
                        <LeafIcon icon={'circle-question'} />
                    </IconButton>
                    <Dialog onClose={handleClose} open={open}>
                        <DialogTitle>
                            <CoreTypography variant="h5">
                                <FormattedMessage
                                    description="Title for support modal"
                                    defaultMessage="Support"
                                />
                            </CoreTypography>
                        </DialogTitle>
                        <DialogContent>
                            <Stack direction="column" spacing={2}>
                                <CoreTypography variant="body1">
                                    <FormattedMessage
                                        description="Device ID title"
                                        defaultMessage="Device ID: {id}"
                                        values={{
                                            id: deviceId
                                        }}
                                    />
                                </CoreTypography>
                                {appVersion && (
                                    <CoreTypography variant="body1">
                                        <FormattedMessage
                                            description="App version title"
                                            defaultMessage="App version: {version}"
                                            values={{
                                                version: appVersion
                                            }}
                                        />
                                    </CoreTypography>
                                )}
                            </Stack>
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </>
    )
}

export default DeviceIdDialog
