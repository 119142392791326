import React from 'react'
import { AppBar, Typography, Link, Box } from '@mui/material'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import {
    LeafIcon,
    useTheme,
    IconButton
} from '@thriveglobal/thrive-web-leafkit'
import { ROUTES } from '../../../routes'
import { RedirectType } from '../../layouts/layoutTypes'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { LanguageSelector } from '../LanguageSelector/LanguageSelector'

interface NavBarProps {
    variant?: 'light' | 'dark'
    redirect?: RedirectType
    canGoBack?: boolean
    backRoute?: string | (() => void)
}

export function getRedirectUrl(redirect?: RedirectType) {
    if (!redirect) {
        return null
    }
    if (redirect === 'login') {
        return ROUTES.LANDING
    } else {
        return ROUTES.BRAND_SELECT
    }
}

const NavBar: React.FC<NavBarProps> = ({
    variant,
    redirect,
    backRoute,
    canGoBack = false
}) => {
    const history = useHistory()
    const theme = useTheme()
    const Logo = theme.Logo
    const componentVariant = variant || 'dark'
    const appBarColor =
        componentVariant === 'light'
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText

    const intl = useIntl()
    const redirectUrl = getRedirectUrl(redirect)

    const messages = defineMessages({
        loginLink: {
            defaultMessage: 'Sign In',
            description: 'Label for link to Sign In'
        },
        signupLink: {
            defaultMessage: 'Sign Up',
            description: 'Label for link to Sign Up'
        },
        supportSubject: {
            defaultMessage: 'Web App Support',
            description: 'Subject line for email to Support'
        }
    })

    const handleBackClick = () => {
        if (backRoute && typeof backRoute === 'function') {
            backRoute()
        } else {
            backRoute ? history.push(backRoute) : history.goBack()
        }
    }

    return (
        <AppBar
            sx={{
                position: 'static',
                color: appBarColor,
                backgroundColor: 'transparent',
                height: 'var(--navbar-height)',
                border: 'none',
                boxShadow: 'none',
                px: 3,
                py: 2
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                {canGoBack ? (
                    <IconButton
                        aria-label="Go Back"
                        onClick={handleBackClick}
                        color={
                            componentVariant === 'light' ? 'primary' : 'info'
                        }
                    >
                        <LeafIcon icon={'chevron-left'} />
                    </IconButton>
                ) : (
                    <Box sx={{ width: '40px', svg: { padding: 0 } }}>
                        <Logo />
                    </Box>
                )}
                <Box sx={{ display: 'flex' }}>
                    {redirectUrl && (
                        <>
                            <Link
                                to={redirectUrl}
                                component={RouterLink}
                                sx={{ color: appBarColor, margin: 'auto' }}
                            >
                                <Typography
                                    sx={{
                                        color: appBarColor,
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '1.5rem'
                                        }
                                    }}
                                    variant="body1"
                                >
                                    {redirect === 'login'
                                        ? intl.formatMessage(messages.loginLink)
                                        : intl.formatMessage(
                                              messages.signupLink
                                          )}
                                </Typography>
                            </Link>
                            <Typography
                                sx={{
                                    marginLeft: '1rem',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '1.5rem'
                                    }
                                }}
                                variant="body1"
                            >
                                {'|'}
                            </Typography>
                        </>
                    )}
                    <Link
                        href={`mailto:support@thriveglobal.com?subject=${intl.formatMessage(
                            messages.supportSubject
                        )}`}
                        sx={{
                            marginLeft: '1rem',
                            color: appBarColor,
                            marginTop: 'auto',
                            marginBottom: 'auto'
                        }}
                    >
                        <Typography
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '1.5rem'
                                }
                            }}
                            variant="body1"
                        >
                            <FormattedMessage
                                description="Label for link to support"
                                defaultMessage="Support"
                            />
                        </Typography>
                    </Link>
                    <Typography
                        sx={{
                            margin: 'auto 1rem auto 1rem',
                            [theme.breakpoints.down('sm')]: {
                                fontSize: '1.5rem'
                            }
                        }}
                        variant="body1"
                    >
                        {'|'}
                    </Typography>

                    <LanguageSelector variant={variant} />
                </Box>
            </Box>
        </AppBar>
    )
}

export default NavBar
