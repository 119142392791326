import React, { useState } from 'react'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    styled
} from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import {
    getBestFitLocale,
    setLocale,
    store
} from '@thriveglobal/thrive-web-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'

const messages = defineMessages({
    languageInputLabel: {
        defaultMessage: 'Language',
        description: 'Label for language field'
    },
    languageSelectorAriaLabel: {
        defaultMessage: 'Choose your language',
        description: 'Aria label for language selector'
    }
})

const availableLocales = [
    { value: 'en-US', displayName: 'English' },
    { value: 'es-419', displayName: 'Español' },
    { value: 'fr-CA', displayName: 'Français (Canada)' },
    { value: 'fr-FR', displayName: 'Français (France)' },
    { value: 'it-IT', displayName: 'Italiano' },
    { value: 'de-DE', displayName: 'Deutsch' },
    { value: 'pt-BR', displayName: 'Português' },
    { value: 'ja-JP', displayName: '日本語' },
    { value: 'zh-Hans', displayName: '简体中文' }
]

interface NavBarProps {
    variant?: 'light' | 'dark'
}

const StyledSelect = styled(Select)(() => ({
    '&.MuiOutlinedInput-root': {
        '.MuiSelect-outlined': {
            paddingLeft: 0
        },
        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0);'
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0);'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 0);'
        }
    }
}))

export const LanguageSelector = ({ variant }: NavBarProps) => {
    const intl = useIntl()
    const theme = useTheme()
    const componentVariant = variant || 'dark'
    const appBarColor =
        componentVariant === 'light'
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText
    const [currentLanguage, setCurrentLanguage] = useState(getBestFitLocale())

    const onLanguageChange = (event) => {
        const newLanguage = event.target.value
        if (newLanguage === currentLanguage) {
            return
        }
        setCurrentLanguage(newLanguage)
        store.dispatch(setLocale(newLanguage))
        // Reload for 2 reasons: Many screen readers only pick up locale once on page load and our apps don't pick up the change automatically.
        window.location.reload()
    }
    return (
        <FormControl>
            <InputLabel
                id="language-select-label"
                sx={{
                    visibility: 'hidden'
                }}
            >
                {intl.formatMessage(messages.languageInputLabel)}
            </InputLabel>
            <StyledSelect
                sx={{
                    color: appBarColor
                }}
                fullWidth
                id="language-selector"
                value={currentLanguage}
                onChange={onLanguageChange}
                aria-label={intl.formatMessage(
                    messages.languageSelectorAriaLabel
                )}
                label={intl.formatMessage(messages.languageInputLabel)}
                labelId="language-select-label"
            >
                {availableLocales.map((locale) => (
                    <MenuItem
                        value={locale.value}
                        key={locale.value}
                        lang={locale.value}
                    >
                        {locale.displayName}
                    </MenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    )
}
